import React from 'react';
// Components
// const Hello = React.lazy(() => import('./Hello'));

const DashboardContainer = React.lazy(() => (
    import('../../contractor/dashboard/DashboardContainer')
));
const EquipmentContainer = React.lazy(() => (
    import('../../contractor/equipment/EquipmentContainer')
));
const SkyviewContainer = React.lazy(() => (
    import('../../contractor/skyview/SkyviewContainer')
));
const ReportContainer = React.lazy(() => (
    import('../../contractor/reports/ReportContainer')
));
const SettingsContainer = React.lazy(() => (
    import('../../settings/SettingsContainer')
));
const Page404 = React.lazy(() => (
    import('../components/Page404')
));

export default [
    {
        path: ['/settings', '/settings/threshold/:organizationID'],
        component: SettingsContainer,
        errorMessage: 'BUG PAGE :: Settings Container Crashed',
        tool: 'Settings'
    },
    {
        path: ['/contractor/dashboard', '/contractor/dashboard/:organizationID'], 
        component: DashboardContainer,
        errorMessage: 'BUG PAGE :: Dashboard Container Crashed',
        tool: 'Dashboard'
    },
    {
        path: ['/contractor/skyview', '/contractor/skyview/:organizationID'],
        component: SkyviewContainer,
        errorMessage: 'BUG PAGE :: Skyview Container Crashed',
        tool: 'Skyview'
    },
    {
        path: ['/contractor/equipment', '/contractor/equipment/:organizationID'], 
        component: EquipmentContainer,
        errorMessage: 'BUG PAGE :: Equipment Container Crashed',
        tool: 'Equipment'
    },
    {
        path: ['/contractor/reports', '/contractor/reports/:organizationID'], 
        component: ReportContainer,
        errorMessage: 'BUG PAGE :: Report Container Crashed',
        tool: 'Report'
    },
    {
        path: '/*',
        errorMessage: 'BUG PAGE :: 404',
        tool: '404',
        component: Page404,
    }

];
