import Immutable, { merge } from 'immutable';
import history from "..//history";
import { API } from '../../Root';
import * as Sentry from '@sentry/browser';
import { catchBlockErrors } from '../utils/sentry';

export const appActionTypes = {    
    'user/setUserData': 'user/setUserData',
    'org/setOrg': 'org/setOrg',
    'user/setTimezone': 'user/setTimezone',
    'app/setModuleTool': 'app/setModuleTool',
    'app/showSnackbar': 'app/showSnackbar',
    'app/removeSnackbar': 'app/removeSnackbar',
    'app/updateUser': 'app/updateUser'
};

export const setTimezone = (apiResponse) => ({
    type: appActionTypes['user/setTimezone'],
    apiResponse
})

export const setOrganization = (org) => ({
    type: appActionTypes['org/setOrg'],
    org
})

export const setUserData = (user) => ({
    type: appActionTypes['user/setUserData'],
    user,
});

export const updateUser = (user) => ({
    // user is apiResponse
    type: appActionTypes['app/updateUser'],
    user
});

// this gets past the an array of the url values after login
// we need to add checks to make sure the value we set are actual values and not
// a 404 url
export const setModuleAndTool = (urlArray) => ({
    type: appActionTypes['app/setModuleTool'],
    urlArray
});

export const removeSnackbar = (key) => {
    return {
        type: appActionTypes['app/removeSnackbar'],
        key
    }
};

export const showSnackbar = ({ message, persist=false, variant="default" }) => {
    return {
        type: appActionTypes['app/showSnackbar'],
        notification : Immutable.fromJS({
            message,
            options: {
                // key: unique id'ing for closing purposes
                key: new Date().getTime() + Math.random(),
                // false = auto dismiss snackbar
                persist,
                // Dictates coloring of snackbar
                variant
            }
        })
    }
};

// Thunk
export const setAppStateAndUrlAction = (urlArray, userId) => {
    return async(dispatch) => {
        try{
            let homepage;
            let orgID = '';

            const userResponse = await API.subscribe(
                {
                    endpoint: API.endpoints['SL/userInfo'],
                    options: {
                        // baseURL: 'https://d.spec-rite.io/api/v2/'

                    },
                }, setUserData
            )
            if(userResponse && !userResponse.error){
                const id = userResponse.get('_id', 'Id not found');
                const username = userResponse.get('username', 'username not found');
                const email = userResponse.get('email', 'email not found');
                const _userObj = { id, username, email };

                // setting sentry user scope
                Sentry.configureScope(scope => { scope.setUser(_userObj) });
                Sentry.configureScope(scope => { scope.setTag("User", username) })

                if(urlArray.length === 4){
                    homepage = urlArray;
                    orgID = urlArray[3];

                } else if (userResponse.size && urlArray.length !== 4){
                    orgID = userResponse.get('organizationID', 'orgIDNotFound');
                    homepage = userResponse.get(
                        'homepage', `/contractor/dashboard/${orgID}`
                    );
                    homepage = homepage.split('/')
                    // orgID = homepage[3]
                }

                await API.subscribe({
                    endpoint: API.endpoints['SL/orgInfo'],
                    method: 'get',
                }, setOrganization);

                history.push(homepage.join('/'));
                return dispatch(setModuleAndTool(homepage));
            }
        } catch(error){
            catchBlockErrors({error, fileFunction: 'app_s/setAppStateAndUrlAction'});
        };
    }
}


const _shapeModulesAndToolsObj = (modules) => {
    const _modules = Immutable.fromJS([
        {
            label: 'Contractor',
            _id: 'contractor',
            tools: [
                {
                    _id: 'dashboard',
                    label: 'Dashboard',
                    path: '/contractor/dashboard'
                },
                {
                    _id: 'skyview',
                    label: 'Skyview',
                    path: '/contractor/skyview'
                },
                {
                    _id: 'reports',
                    label: 'Reports',
                    path: '/contractor/reports'
                },
                {
                    _id: 'equipment',
                    label: 'Equipment',
                    path: '/contractor/equipment'
                }
            ]
        },
        // {
        //     label: 'Markings',
        //     _id: 'markings',
        //     tools: [
        //         {
        //             _id: 'dashboard',
        //             label: 'Dashboard',
        //             path: '/markings/dashboard'
        //         },
        //         {
        //             _id: 'skyview',
        //             label: 'Skyview',
        //             path: '/markings/skyview'
        //         }
        //     ]
        // }
    ])
    return _modules;
}
// Reducer
const initState = Immutable.fromJS({});

const modules = ['contractor', 'markings'];
// Had to add 'reports' to this array to make it highlighted in sidebar;
// revisit this to be more dynamic
const tools = ['dashboard', 'skyview', 'equipment', 'reports', 'admin'];

const appReducer = (state=initState, action) => {
    // appStore === GAS
    // const GAS = appStore;

    switch(action.type){

        case appActionTypes['user/setUserData']:
            // case GAS.actionTypes['app/setTokenUser']:
            const _userState = state.set('user', action.user)
            const __userState = _userState.setIn(
                ['user', 'modules'], _shapeModulesAndToolsObj(action.user.get('tools'))
            )
            // const ___userState = __userState.setIn(
            //     ['user', 'home_page'], '/contractor/dashboard'
            // )
            return merge(state, __userState);

        case appActionTypes['app/updateUser']:
            const user = action.user;
            const _updatedUser = user.set(
                'modules', _shapeModulesAndToolsObj(user.get('tools'))
            )
            
            let updatedUserState;
            
            if (!user.error) {

                updatedUserState = state.set('user', _updatedUser);
                const snackbarMessageUpdate = Immutable.fromJS({
                    message: "Update successful!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        persist: false,
                        variant: "success"
                    },
                });

                const snackSuccessState = updatedUserState
                    .get("notifications", Immutable.List([]))
                    .push(snackbarMessageUpdate);

                updatedUserState = updatedUserState.set("notifications", snackSuccessState);
               

            } else {
                const snackbarMessageFail = Immutable.fromJS({
                    message: "Failed to update",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        persist: true,
                        variant: "error"
                    }
                })
                const snackFailState = state
                    .get("notifications", Immutable.List([]))
                    .push(snackbarMessageFail);

                updatedUserState = state.set("notifications", snackFailState);
            }
            
            return merge(state, updatedUserState);

        case appActionTypes['org/setOrg']:
            const org = action.org;
            const _orgIDState = state.set('organization', org);


            return merge(state, _orgIDState);

        case appActionTypes['user/setTimezone']:
            const _timezoneResponse = action.apiResponse;
            const _timezone = _timezoneResponse.get('timezone', '');
            const _timezoneState = state.setIn(['organization', 'timezone'], _timezone);


            return merge(state, _timezoneState);


        case appActionTypes['app/setModuleTool']:
            const urlArray = action.urlArray || [];
            let _urlState = Immutable.fromJS(state);

            urlArray.forEach((value, index) => {
                if(index === 1 && modules.includes(value)){
                    _urlState = _urlState.set('module', value);
                } else if(index === 2 && tools.includes(value)){
                    _urlState = _urlState.set('tool', value);
                }
            })


            return state.merge(_urlState);

        case appActionTypes['app/showSnackbar']:
            const snackbarArray = state.get("notifications", Immutable.List([]))
                .push(action.notification);
            const snackbarState = state.set("notifications", snackbarArray);

            return merge(state, snackbarState);

        case appActionTypes['app/removeSnackbar']:
            const snackbarRemoveArray = state.get("notifications", Immutable.List([]));
            // returns all others BUT the one with the passed in key
            const filteredArray = snackbarRemoveArray.filterNot(snack => {
                const snackKey = snack.getIn(["options", "key"], "");
                return snackKey === action.key
            });
            const filteredState = state.set("notifications", filteredArray);

            return merge(state, filteredState);

        default:
            return state
    };
};


export default appReducer;
