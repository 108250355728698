import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setModuleAndTool } from '../shared/stores/app_s';
import {
    Divider,
    Drawer,
    IconButton,
    List as Ul,
    ListItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    makeStyles
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


SideBarContainer.propTypes = {
    //func
    toggleSideBar: PropTypes.func.isRequired,
    //vars
    sideBarOpen: PropTypes.bool.isRequired
}

const useStyles = makeStyles({

    paper: {
        background: "#f0efef", // overriding background of white
        width: 200,
        // overflowY: "scroll"
    }
});


function SideBarContainer(props) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const { toggleSideBar, sideBarOpen } = props;

    const [openPanelID, setOpenPanel] = useState('');

    const clearPanelIDAndCloseSideBar = () => {
        toggleSideBar();
        setOpenPanel('');
    }

    const {
        modules,
        module,
        toolInUrl,
        organizationID,
    } = useSelector(state => ({
        modules: state.app.getIn(['user', 'modules'], List([])),
        module: state.app.get('module', ''),
        toolInUrl: state.app.get('tool', ''),
        organizationID: state.app.getIn(['user', 'organizationID'], 'noOrgID')
    }))
    
    // console.log("SideBarContainer -> modules", modules.toJS());

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={sideBarOpen}
            classes={{ paper: classes.paper }}
        >
            <IconButton onClick={clearPanelIDAndCloseSideBar}>
                <ChevronLeftIcon />
            </IconButton>
            {modules.map(m => {

                const moduleID = m.get('_id', 'no_id');
                const panelOpen = (moduleID === openPanelID || module === moduleID);

                const handlePanelClick = (event, expanded) => {
                    if (expanded) setOpenPanel(moduleID);
                    else setOpenPanel('')
                };

                return (
                    <React.Fragment key={moduleID}>
                        <Accordion
                            value={moduleID}
                            expanded={panelOpen}
                            onChange={handlePanelClick}
                            style={ {background: "#f0efef" }}
                        >
                            <AccordionSummary>
                                <Typography style={{fontWeight: "bold"}}>
                                    {m.get('label', 'no label found')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 0
                                }}
                            >
                                <Ul>
                                    {m.get('tools', List([])).map(tool => {
                                        const thisModule = m.get('_id');
                                        const thisTool = tool.get('_id');
                                        const link = `${tool.get('path', '')}/${organizationID}`
                                        const handleLinkSelect = () => {
                                            const linkArray = link.split('/');
                                            dispatch(setModuleAndTool(linkArray));
                                            clearPanelIDAndCloseSideBar();
                                        };

                                        return (
                                            <ListItem 
                                                key={tool.getIn(['path'], 'no_path')}
                                                style={thisModule === module 
                                                        && thisTool === toolInUrl 

                                                        ? {
                                                            background: "#CF2031",
                                                            color: "white"
                                                        } 
                                                        : { 
                                                            paddingBottom: "4px", 
                                                            color: "black"
                                                        }
                                                }
                                            >
                                                <Link
                                                    to={link}
                                                    onClick={handleLinkSelect}
                                                    style={ 
                                                        {
                                                            textDecoration: "none",
                                                            color: "inherit"
                                                        }}
                                                    >
                                                        <Typography>
                                                            {tool.get('label', 'no label')}
                                                        </Typography>
                                                    </Link>
                                                </ListItem>
                                        )
                                    })}
                                </Ul>
                            </AccordionDetails>
                        </Accordion>
                        <Divider />
                    </React.Fragment>
                )
            })}
            </Drawer>
    )
};


export default SideBarContainer;
